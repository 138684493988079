var render = function render(){var _vm=this,_c=_vm._self._c;return _c('both-colum-page',{attrs:{"title":_vm.$t('areaManage.title'),"formData":_vm.formData,"config":_vm.config},on:{"search":_vm.search,"functionMethod":_vm.functionMethod}},[_c('div',{attrs:{"slot":"left-title"},slot:"left-title"},[_c('span',[_vm._v(_vm._s(_vm.$t('areaManage.leftTitle')))]),(_vm.$page.showBtn('COMPANY_AREA_LABEL_MANAGE_AREA_ADD'))?_c('el-button',{staticStyle:{"position":"absolute","top":"10px","right":"10px","font-size":"12px"},attrs:{"icon":"el-icon-plus","size":"mini"},on:{"click":_vm.addFirstArea}},[_vm._v(" "+_vm._s(_vm.$t('userGroup.addFirstGroup'))+" ")]):_vm._e()],1),_c('base-tree',{ref:"baseTree",attrs:{"slot":"left","url":'/' + _vm.$util.getLocalStorage('apiprefix') + _vm.$api.dictFindTree,"defaultParams":_vm.params},on:{"nodeClick":_vm.nodeClick},slot:"left",scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","width":"100%"}},[_c('div',[_vm._v(_vm._s(data.value))]),_c('div',{staticClass:"groupOptions"},[(
              data.level !== 'THREE' &&
              _vm.$page.showBtn('COMPANY_AREA_LABEL_MANAGE_AREA_ADD')
            )?_c('i',{staticClass:"el-icon-plus",on:{"click":function($event){$event.stopPropagation();return _vm.groupMethod(data, 'add')}}}):_vm._e(),(_vm.$page.showBtn('COMPANY_AREA_LABEL_MANAGE_AREA_UPDATE'))?_c('i',{staticClass:"el-icon-edit",on:{"click":function($event){$event.stopPropagation();return _vm.groupMethod(data, 'edit')}}}):_vm._e(),(_vm.$page.showBtn('COMPANY_AREA_LABEL_MANAGE_AREA_DEL'))?_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){$event.stopPropagation();return _vm.groupMethod(data, 'del')}}}):_vm._e()])])]}}]),model:{value:(_vm.treeRow),callback:function ($$v) {_vm.treeRow=$$v},expression:"treeRow"}}),(_vm.formData.currentLabelType === 'ble')?_c('table-list',{ref:"bleTableList",attrs:{"url":'/' + _vm.$util.getLocalStorage('apiprefix') + _vm.$api.bleLabelList,"columns":_vm.bleColumns,"default-params":{
        needFullArea: 'YES',
        installAreaId: 0,
        companyUid: _vm.$store.getters.company.uid,
      }},scopedSlots:_vm._u([{key:"area",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.fullArea(row))+" ")]}},{key:"photo",fn:function({ row }){return [(row.file)?_c('el-image',{attrs:{"src":row.file.fileHost + '/' + row.file.objectName,"preview-src-list":[row.file.fileHost + '/' + row.file.objectName]}}):_vm._e()]}}],null,false,977975151),model:{value:(_vm.tableRow),callback:function ($$v) {_vm.tableRow=$$v},expression:"tableRow"}}):_vm._e(),(_vm.formData.currentLabelType === 'rfid')?_c('table-list',{ref:"rfidTableList",attrs:{"url":'/' + _vm.$util.getLocalStorage('apiprefix') + _vm.$api.rfidLabelList,"columns":_vm.rfidColumns,"default-params":{
        needFullArea: 'YES',
        installAreaId: 0,
        companyUid: _vm.$store.getters.company.uid,
      }},scopedSlots:_vm._u([{key:"area",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.fullArea(row))+" ")]}},{key:"photo",fn:function({ row }){return [(row.file)?_c('el-image',{attrs:{"src":row.file.fileHost + '/' + row.file.objectName,"preview-src-list":[row.file.fileHost + '/' + row.file.objectName]}}):_vm._e()]}}],null,false,977975151),model:{value:(_vm.tableRow),callback:function ($$v) {_vm.tableRow=$$v},expression:"tableRow"}}):_vm._e(),(_vm.showDetail)?_c('detail',{attrs:{"tableRow":_vm.treeRow,"language":_vm.params.language,"dictType":_vm.params.dictType},on:{"closePage":_vm.closeTreePage}}):_vm._e(),(_vm.showLabelDetail)?_c('label-detail',{attrs:{"label-type":_vm.formData.currentLabelType,"table-row":_vm.tableRow},on:{"closePage":_vm.closeLabelDetailPage}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }