<template>
  <both-colum-page
      :title="$t('areaManage.title')"
      :formData="formData"
      :config="config"
      @search="search"
      @functionMethod="functionMethod"
  >
    <div slot="left-title">
      <span>{{ $t('areaManage.leftTitle') }}</span>
      <el-button
          v-if="$page.showBtn('COMPANY_AREA_LABEL_MANAGE_AREA_ADD')"
          style="position: absolute; top: 10px; right: 10px; font-size: 12px"
          icon="el-icon-plus"
          size="mini"
          @click="addFirstArea"
      >
        {{ $t('userGroup.addFirstGroup') }}
      </el-button>
    </div>
    <base-tree
        slot="left"
        ref="baseTree"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.dictFindTree"
        :defaultParams="params"
        v-model="treeRow"
        @nodeClick="nodeClick"
    >
      <template v-slot:default="{ data }">
        <div style="display: flex; justify-content: space-between; width: 100%">
          <div>{{ data.value }}</div>
          <div class="groupOptions">
            <i
                v-if="
                data.level !== 'THREE' &&
                $page.showBtn('COMPANY_AREA_LABEL_MANAGE_AREA_ADD')
              "
                class="el-icon-plus"
                @click.stop="groupMethod(data, 'add')"
            />
            <i
                v-if="$page.showBtn('COMPANY_AREA_LABEL_MANAGE_AREA_UPDATE')"
                class="el-icon-edit"
                @click.stop="groupMethod(data, 'edit')"
            />
            <i
                v-if="$page.showBtn('COMPANY_AREA_LABEL_MANAGE_AREA_DEL')"
                class="el-icon-delete"
                @click.stop="groupMethod(data, 'del')"
            />
          </div>
        </div>
      </template>
    </base-tree>
    <table-list
        v-if="formData.currentLabelType === 'ble'"
        ref="bleTableList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.bleLabelList"
        :columns="bleColumns"
        :default-params="{
          needFullArea: 'YES',
          installAreaId: 0,
          companyUid: $store.getters.company.uid,
        }"
        v-model="tableRow"
    >
      <template v-slot:area="{ row }">
        {{ fullArea(row) }}
      </template>
      <template v-slot:photo="{ row }">
        <el-image v-if="row.file" :src="row.file.fileHost + '/' + row.file.objectName" :preview-src-list="[row.file.fileHost + '/' + row.file.objectName]"/>
      </template>
    </table-list>
    <table-list
        v-if="formData.currentLabelType === 'rfid'"
        ref="rfidTableList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.rfidLabelList"
        :columns="rfidColumns"
        :default-params="{
          needFullArea: 'YES',
          installAreaId: 0,
          companyUid: $store.getters.company.uid,
        }"
        v-model="tableRow"
    >
      <template v-slot:area="{ row }">
        {{ fullArea(row) }}
      </template>
      <template v-slot:photo="{ row }">
        <el-image v-if="row.file" :src="row.file.fileHost + '/' + row.file.objectName" :preview-src-list="[row.file.fileHost + '/' + row.file.objectName]"/>
      </template>
    </table-list>
    <detail
        v-if="showDetail"
        :tableRow="treeRow"
        :language="params.language"
        :dictType="params.dictType"
        @closePage="closeTreePage"
    />
    <label-detail
        v-if="showLabelDetail"
        :label-type="formData.currentLabelType"
        :table-row="tableRow"
        @closePage="closeLabelDetailPage"
    />
  </both-colum-page>
</template>

<script>
import Detail from './detail'
import LabelDetail from './labelDetail'

export default {
  components: {
    Detail,
    LabelDetail
  },
  data() {
    return {
      // 搜索
      formData: {
        currentLabelType: 'ble',
        installAreaId: 0,
      },
      config: [
        // // lang:账号类型
        // {
        //   tag: 'el-select',
        //   label: this.$t('adminAccount.type'),
        //   prop: 'type',
        //   span: 8,
        //   tagProps: {
        //     options: this.$dict.userAccountType(),
        //   },
        // },
        // lang:订阅模式
        // {
        //   tag: 'el-select',
        //   label: this.$t('adminAccount.subscribeType'),
        //   prop: 'subscribeType',
        //   span: 8,
        //   tagProps: {
        //     options: this.$dict.subscribeType(),
        //   },
        // },
        // lang:是否在线支持人员
        {
          tag: 'el-select',
          label: this.$t('areaManage.labelType'),
          prop: 'currentLabelType',
          span: 8,
          tagProps: {
            options: this.$dict.labelType(),
          },
        },
      ],
      // 左边树
      treeRow: null,
      showDetail: false,
      showLabelDetail: false,
      isFirstArea: false,
      // 表格页
      tableRow: null,
      bleColumns: [
        // lang:标签名称
        {
          label: this.$t('labelManage.labelName'),
          prop: 'name',
        },
        // lang:区域
        {
          tag: 'slot-content',
          label: this.$t('labelManage.area'),
          prop: 'area',
          width: 200,
        },
        // lang:类型
        // {
        //   tag: 'slot-content',
        //   label: this.$t('labelManage.labelType'),
        //   prop: 'labelType',
        // },
        // lang:标签ID
        {
          label: this.$t('labelManage.labelID'),
          prop: 'labelId',
        },
        // lang:安装位置
        {
          label: this.$t('labelManage.installAddress'),
          prop: 'installAddress',
        },
        // lang:被发现名称
        {
          label: this.$t('labelManage.discoveredName'),
          prop: 'discoverName',
        },
        // lang:RSSI:0.5m内
        {
          label: this.$t('labelManage.RSSI0_5'),
          prop: 'rssiOneInterval',
        },
        // lang:RSSI:1.0m内
        {
          label: this.$t('labelManage.RSSI1_0'),
          prop: 'rssiOnePointFiveInterval',
        },
        // lang:RSSI:1.5m内
        {
          label: this.$t('labelManage.RSSI1_5'),
          prop: 'rssiZeroPointFiveInterval',
        },
        // lang:电池余量
        // {
        //   label: this.$t('labelManage.LOBAT'),
        //   prop: 'accountId',
        // },
        // lang:现场照片
        {
          tag: 'slot-content',
          label: this.$t('labelManage.sitePhotos'),
          prop: 'photo',
          width: 200,
        },
      ],
      rfidColumns: [
        // lang:标签名称
        {
          label: this.$t('labelManage.labelName'),
          prop: 'name',
        },
        // lang:区域
        {
          tag: 'slot-content',
          label: this.$t('labelManage.area'),
          prop: 'area',
          width: 200,
        },
        // lang:类型
        // {
        //   tag: 'slot-content',
        //   label: this.$t('labelManage.labelType'),
        //   prop: 'labelType',
        // },
        // lang:标签ID
        {
          label: this.$t('labelManage.labelID'),
          prop: 'labelId',
        },
        // lang:安装位置
        {
          label: this.$t('labelManage.installAddress'),
          prop: 'installAddress',
        },
        // lang:被发现名称
        // {
        //   label: this.$t('labelManage.discoveredName'),
        //   prop: 'discoverName',
        // },
        // lang:RSSI:0.5m内
        // {
        //   label: this.$t('labelManage.RSSI0_5'),
        //   prop: 'rssiOneInterval',
        // },
        // lang:RSSI:1.0m内
        // {
        //   label: this.$t('labelManage.RSSI1_0'),
        //   prop: 'rssiOnePointFiveInterval',
        // },
        // lang:RSSI:1.5m内
        // {
        //   label: this.$t('labelManage.RSSI1_5'),
        //   prop: 'rssiZeroPointFiveInterval',
        // },
        // lang:电池余量
        // {
        //   label: this.$t('labelManage.LOBAT'),
        //   prop: 'accountId',
        // },
        // lang:现场照片
        {
          tag: 'slot-content',
          label: this.$t('labelManage.sitePhotos'),
          prop: 'photo',
          width: 200,
        },
      ],
      params: {
        language: 'zhCN',
        dictType: 'LABEL_INSTALL_AREA',
      },
    }
  },
  watch: {
    'formData.currentLabelType'(val) {
      this.$nextTick(() => {
        if(val === 'ble') {
          this.$refs.bleTableList.searchList(this.formData)
        } else if(val === 'rfid') {
          this.$refs.rfidTableList.searchList(this.formData)
        }
      })
    }
  },
  methods: {
    fullArea(row) {
      let area = ''
      if(row.installArea.parent) {
        const one = row.installArea.parent.filter(item => item.level === 'ONE')
        const two = row.installArea.parent.filter(item => item.level === 'TWO')
        if(one.length > 0) {
          area += one[0].value
        }
        if(two.length > 0) {
          area += two[0].value
        }
      }
      area += row.installArea.value
      return area
    },
    // 新增一级分组
    addFirstArea() {
      this.$store.commit('updateCmd', 'add')
      this.$store.commit('updateDialogTitle', this.$t('base.add'))
      this.showDetail = true
    },
    // 分组按钮操作方法
    groupMethod(data, method) {
      if (method === 'del') {
        this.deleteMethod('/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictDelete, data)
        return
      }
      this.treeRow = data
      this.$store.commit('updateCmd', method)
      this.$store.commit('updateDialogTitle', this.$t(`base.${method}`))
      this.showDetail = true
    },
    // 删除
    deleteMethod(url, data) {
      this.$element
          .showConfirm({
            content: this.$t('base.deleteConfirm'),
          })
          .then(() => {
            this.$ajax({
              url,
              data,
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
              this.closeTreePage('delete')
            })
          })
    },
    nodeClick(data) {
      this.formData.installAreaId = data.id
      this.search()
    },
    // 关闭详情页回调
    closeTreePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.$refs.baseTree.searchList()
      }
    },
    closeLabelDetailPage(method) {
      this.showLabelDetail = false
    },
    search(isActiveQuery){
      if (this.formData.currentLabelType === 'ble') {
        this.$refs.bleTableList.searchList(this.formData, isActiveQuery)
      }
      if (this.formData.currentLabelType === 'rfid') {
        this.$refs.rfidTableList.searchList(this.formData, isActiveQuery)
      }
    },
    functionMethod(item) {
      console.log(item)
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      if (
          method === 'add' &&
          this.tableRow &&
          this.params.dictType === 'VERSION_CATEGORY'
      ) {
        this.$element.showMsg(this.$t('base.canAddFirstCate'), 'warning')
        return
      }
      switch (method) {
        case 'view':
          this.showLabelDetail = true
          break
        default: // do something
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/style/theme.scss';

.groupOptions {
  i {
    margin: 0 5px;
    color: $--color-primary;

    &:hover {
      font-weight: bold;
    }
  }
}
</style>
