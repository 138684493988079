<template>
  <dialog-form-list
      :config="labelType === 'ble' ? bleConfig : rfidConfig"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
  >
    <template v-slot:fileId>
      <div v-if="!fileSrc" class="upload-no-img" @click="$refs.uploadfile.click()">+</div>
      <img v-else :src="fileSrc" class="upload-has-img" @click="$refs.uploadfile.click()"/>
      <input type="file" style="display: none;" ref="uploadfile" @change="uploadFile"/>
    </template>
  </dialog-form-list>
</template>

<script>

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
    language: {
      // 语言类型
      type: String,
      default: '',
    },
    labelType: {
      // 标签类型
      type: String,
      default: 'ble',
    },
  },
  data() {
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      fileId: null,
      fileSrc: null,
      bleConfig: [
        // lang:名称
        {
          label: this.$t('labelManage.labelName'),
          prop: 'name',
          rules: {
            noNull: true,
          },
        },
        {
          label: this.$t('labelManage.labelID'),
          prop: 'labelId',
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 30,
            showWordLimit: true,
          },
        },
        {
          tag: 'el-cascader',
          label: this.$t('labelManage.installArea'),
          prop: 'installAreaId',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
        {
          label: this.$t('labelManage.installAddress'),
          prop: 'installAddress',
          rules: {
            noNull: true,
          },
        },
        {
          label: this.$t('labelManage.discoveredName'),
          prop: 'discoverName',
          rules: {
          },
        },
        {
          label: this.$t('labelManage.RSSI0_5') + ' >',
          prop: 'rssiZeroPointFiveInterval',
          rules: {
          },
          tagProps: {
            type: 'number',
          }
        },
        {
          label: this.$t('labelManage.RSSI1_0') + ' >',
          prop: 'rssiOneInterval',
          rules: {
          },
          tagProps: {
            type: 'number',
          }
        },
        {
          label: this.$t('labelManage.RSSI1_5') + ' >',
          prop: 'rssiOnePointFiveInterval',
          rules: {
          },
          tagProps: {
            type: 'number',
          }
        },
        {
          tag: 'slot-content',
          label: this.$t('labelManage.sitePhotos'),
          prop: 'fileId',
        },
        {
          label: this.$t('labelManage.remarks'),
          prop: 'note',
          rules: {
          },
          tagProps: {
            type: 'textarea',
            rows: 4,
          },
        },
      ],
      rfidConfig: [
        // lang:名称
        {
          label: this.$t('labelManage.labelName'),
          prop: 'name',
          rules: {
            noNull: true,
          },
        },
        {
          label: this.$t('labelManage.labelID'),
          prop: 'labelId',
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 30,
            showWordLimit: true,
          },
        },
        {
          tag: 'el-cascader',
          label: this.$t('labelManage.installArea'),
          prop: 'installAreaId',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
        {
          label: this.$t('labelManage.installAddress'),
          prop: 'installAddress',
          rules: {
            noNull: true,
          },
        },
        // {
        //   label: this.$t('labelManage.discoveredName'),
        //   prop: 'discoverName',
        //   rules: {
        //   },
        // },
        // {
        //   label: this.$t('labelManage.RSSI0_5') + ' >',
        //   prop: 'rssiZeroPointFiveInterval',
        //   rules: {
        //   },
        //   tagProps: {
        //     type: 'number',
        //   }
        // },
        // {
        //   label: this.$t('labelManage.RSSI1_0') + ' >',
        //   prop: 'rssiOneInterval',
        //   rules: {
        //   },
        //   tagProps: {
        //     type: 'number',
        //   }
        // },
        // {
        //   label: this.$t('labelManage.RSSI1_5') + ' >',
        //   prop: 'rssiOnePointFiveInterval',
        //   rules: {
        //   },
        //   tagProps: {
        //     type: 'number',
        //   }
        // },
        {
          tag: 'slot-content',
          label: this.$t('labelManage.sitePhotos'),
          prop: 'fileId',
        },
        {
          label: this.$t('labelManage.remarks'),
          prop: 'note',
          rules: {
          },
          tagProps: {
            type: 'textarea',
            rows: 4,
          },
        },
      ],
      storeApi: this.$props.labelType === 'ble'?this.$api.bleLabelStore:this.$api.rfidLabelStore,
      updateApi: this.$props.labelType === 'ble'?this.$api.bleLabelUpdate:this.$api.rfidLabelUpdate,
    }
  },
  mounted() {
    this.dictFindTree()
    this.setDefaultValue()
  },
  methods: {
    uploadFile() {
      const formData = new FormData()
      formData.append('fileFrom', 'LABEL_INSTALL_ADDRESS_IMG')
      formData.append('file', this.$refs.uploadfile.files[0])
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.coreFileupload,
        data: formData,
      }).then(res => {
        console.log(res)
        this.fileId = res.id
        this.fileSrc = res.fileHost + '/' + res.objectName
      })
    },
    dictFindTree() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          language: 'zhCN',
          dictType: 'LABEL_INSTALL_AREA',
        },
      }).then(res => {
        if(this.labelType === 'ble') {
          this.bleConfig[2].tagProps.options = res
        }
        if(this.labelType === 'rfid') {
          this.rfidConfig[2].tagProps.options = res
        }
      })
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          this.formData.installAreaId = []
          if(this.tableRow.installArea.parent) {
            const one = this.tableRow.installArea.parent.filter(item => item.level === 'ONE')
            const two = this.tableRow.installArea.parent.filter(item => item.level === 'TWO')
            if(one.length > 0) {
              this.formData.installAreaId.push(one[0].id)
            }
            if(two.length > 0) {
              this.formData.installAreaId.push(two[0].id)
            }
          }
          this.formData.installAreaId.push(this.tableRow.installArea.id)
          if(this.tableRow.file) {
            this.fileId = this.tableRow.file.id
            this.fileSrc = this.tableRow.file.fileHost + '/' + this.tableRow.file.objectName
          }
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      formData.companyUid = this.$store.getters.company.uid
      formData.accountUid = this.$store.state.operator.uid
      formData.installAreaId = formData.installAreaId.pop()
      if(this.fileId) {
        formData.fileId = this.fileId
      } else {
        formData.fileId = null
      }
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      if (this.tableRow) formData.psn = this.tableRow.sn
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.storeApi,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.updateApi,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-no-img {
  width: 200px;
  height: 200px;
  font-size: 36px;
  line-height: 200px;
  text-align: center;
  border: 1px dashed #cccccc;
  cursor: pointer;
  border-radius: 4px;
}
:hover.upload-no-img {
  border: 1px dashed blue;
  color: blue;
}
.upload-has-img {
  width: 200px;
  height: 200px;
  line-height: 200px;
  cursor: pointer;
  border-radius: 4px;
  object-fit: contain;
  background: black;
}
</style>
